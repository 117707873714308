<template>
  <v-container align-content-center>
    <v-layout align-center justify-center>
      <div class="size">
        Intellectual Property Rights
      </div>
    </v-layout>
    <v-layout align-center justify-center>
      <div class="text-caption txt-center">
        (Date of Last Revision: 15 September 2021)
      </div>
    </v-layout>
    <h3> &sect; 1. Copyright & Trademarks</h3>
    <p>
      All rights reserved. All content (texts, trademarks, illustrations, photos, graphics, files, designs, arrangements etc.) on this website are protected by copyright, patents and other protective laws. The contents of this website are to be used only in accordance with Internet regulations.
    </p>
    <br>
    <h3> &sect; 2. Internet Regulations</h3>
    <p>
      Without the explicit written permission of Edliy it is prohibited to integrate in whole, or in part, any of the protected contents published on these websites into other programs or other web sites or to use them by any other means. This website can contain elements that are protected by copyright and by other laws that are subject to the copyright or other rights of third parties and that are correspondingly protected for these third parties.
    </p>
    <br>
    <h3> &sect; 3.  Liability</h3>
    <p>
      Edliy has carefully compiled the contents of this website in accordance with their current state of knowledge. Access to and use of this website, as well as web sites related or connected to this by links, are at the user's own risk and responsibility. Damage and warranty claims arising from missing or incorrect content are excluded.
      Edliy bears no responsibility or liability for damage of any kind, also for indirect or consequential damages resulting from access to or use of this website or websites related or connected to this by links.
    </p>
    <br>
    <h3> &sect; 4.  Link to External Websites</h3>
    <p>
      The Edliy website can contain links (cross references) to websites that are run by third parties. Edliy takes no responsibility for the content of these other websites.
    </p>
    <br>
    <divide-content />
  </v-container>
</template>
<script>
import { mapGetters } from 'vuex';
import Vue from 'vue';
import DivideContent from './Divider.vue'
export default {
  name: 'IP',
  components: {
    DivideContent
  },
 //async
 created ()  {
//    await this.$store.dispatch('navigation/getData');
    this.$store.commit('navigation/resetState');
		this.$store.commit('navigation/changeTitle', 'Intellectual Property');
		this.$store.commit('navigation/changeMenu', 'Intellectual Property');
		let newshowhome = false;
		this.$store.commit('navigation/toggleshowhome', newshowhome);
		let newIP =true;
		this.$store.commit('navigation/replaceIP', newIP);
	},
  metaInfo() {
    return {
              title: 'Intellectual Property',
            //  titleTemplate: '%s - Learn interactively',
              meta: [
                    {vmid:'description', name: 'description', content: "Find topics in science, engineering, mathematics"},
                    {vmid:'keywords', name: 'description', content: 'IP'},
                    {vmid:'chars', charset: 'utf-8' },
                    {vmid:'viewport', name: 'viewport', content: 'width=device-width, initial-scale=1' },
                    ]
          }
          },
}
</script>
<!--
<style lang="scss">
body {
	background: #f1f1f1;
	font-family: 'Roboto', sans-serif;
}
.IP-form {
	font-size: 20px;
	font-family: Lato;
	max-width: 800px;
	width: 100%;
	border-radius:5px;
	margin: 0 auto;
}
.IP-form .separator {
	border-bottom: solid 1px #ccc;
	margin-bottom: 15px;
}
.IP-form .form {
	display: flex;
	flex-direction: column;
	font-size: 16px;
	max-width: 425px;
	border-radius:5px;
	text-justify:center;
	margin: 0 auto;
	padding-bottom:25px;
}
.IP-form_title {
	color: #333;
	font-size: 32px;
	text-align:center;
	padding-top:20px;
}
.IP-form_subtitle {
	color: #333;
	font-size: 18px;
	text-justify:center;
	padding-left:35px;
	padding-right:15px;
}
</style>
-->
